// /* global google */
// import React, { useEffect, useRef, useState } from 'react';
// import {useSelector} from 'react-redux';

// const Home = ({ isDarkMode }) => {
//     const mapRef = useRef(null);
//     const { accountType } = useSelector((state) => {
//     if (state.profile.user && state.profile.user.accountType) {
//         return state.profile.user.accountType;
//     } else {
//         return { ...state.profile.user, accountType: null };
//     }
// });
//     const [selectedShape, setSelectedShape] = useState(null);
//     const [searchBox, setSearchBox] = useState(null);
//     const [selectedPolygonInfo, setSelectedPolygonInfo] = useState(null);
//     const [selectedColor, setSelectedColor] = useState('#000000'); // Default color is black
//     let drawingManager; // Declare drawingManager variable outside initMap function

//     const clearSelection = () => {
//         if (selectedShape) {
//             setSelectedShape(null);
//         }
//     };

//     const setSelection = (shape) => {
//         setSelectedShape(shape);
//         shape.setEditable(false);
//     };

//     useEffect(() => {
//         const script = document.createElement('script');
//         script.id = 'google-maps-script';
//         script.src =
//             'https://maps.googleapis.com/maps/api/js?key=AIzaSyC9-d4Gq0-oEsTLJ00BpwJ90PacwLbk3rg&callback=initMap&libraries=drawing,places';
//         script.defer = true;

//         window.initMap = function () {
//             if (navigator.geolocation) {
//                 // Get current position
//                 navigator.geolocation.getCurrentPosition(
//                     (position) => {
//                         const userLocation = {
//                             lat: position.coords.latitude,
//                             lng: position.coords.longitude
//                         };
                        
//                         // Create map centered on user's current location
//                         const map = new google.maps.Map(mapRef.current, {
//                             center: userLocation,
//                             zoom: 15,
//                             streetViewControl: false,
//                         });
//                         map.setOptions({ minZoom: 7, maxZoom: 20 });


//             const initAutocomplete = () => {
//                 const input = document.createElement('input');
//                 input.id = 'pac-input';
//                 input.type = 'text';
//                 input.placeholder = 'Search places...';
//                 input.style.width = '50%';
//                 input.style.padding = '10px';
//                 input.style.margin = '10px 0';
//                 input.style.borderRadius = '5px';
//                 input.style.border = '1px solid #ccc';
//                 map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);

//                 const searchBox = new google.maps.places.SearchBox(input);
//                 setSearchBox(searchBox);


//                 searchBox.addListener('places_changed', () => {
//                     const places = searchBox.getPlaces();

//                     if (places.length === 0) {
//                         return;
//                     }


//                     const bounds = new google.maps.LatLngBounds();
//                     places.forEach((place) => {
//                         if (place.geometry && place.geometry.viewport) {
//                             bounds.union(place.geometry.viewport);
//                         } else {
//                             bounds.extend(place.geometry.location);
//                         }

                        
//                     });

//                     map.fitBounds(bounds);

//                 });
//             };

//             drawingManager = new google.maps.drawing.DrawingManager({
//                 drawingMode: google.maps.drawing.OverlayType.POLYGON,
//                 drawingControl: true,
//                 drawingControlOptions: {
//                     position: google.maps.ControlPosition.BOTTOM_CENTER,
//                     drawingModes: [
//                         google.maps.drawing.OverlayType.POLYGON,
//                     ],
//                 },
//                 polygonOptions: {
//                     clickable: true,
//                     fillColor: selectedColor, // Set the initial color from the selectedColor state
//                     fillOpacity: 0.5,
//                     strokeWeight: 3,
//                     zIndex: 1,
//                     editable: false,
//                 },
//             });

//             google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
//                 if (event.type !== google.maps.drawing.OverlayType.MARKER) {
//                     drawingManager.setDrawingMode(null);
            
//                     const newShape = event.overlay;
//                     newShape.type = event.type;
//                     google.maps.event.addListener(newShape, 'click', function () {
//                         setSelection(newShape);
//                     });
//                     setSelection(newShape);
            
//                     const polygon = event.overlay;
//                     setSelectedShape(polygon); // Set the selected shape
            
//                     // Define color options for both border and interior
//                     const colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF'];
            
//                     // Create a container for the info window content
//                     const contentContainer = document.createElement('div');
//                     contentContainer.style.display = 'flex';
//                     contentContainer.style.flexDirection = 'column';
//                     contentContainer.style.padding = '10px';
            
//                     // Create a color picker for the polygon fill color
//                     const fillColorLabel = document.createElement('label');
//                     fillColorLabel.textContent = 'Fill Color:';
//                     const fillColorPicker = createColorPicker(colors, selectedColor);
//                     fillColorPicker.style.marginBottom = '10px';
//                     fillColorPicker.addEventListener('change', function (event) {
//                         const color = event.target.value;
//                         handleFillColorSelection(polygon, color);
//                     });
            
//                     // Create a color picker for the polygon border color
//                     const borderColorLabel = document.createElement('label');
//                     borderColorLabel.textContent = 'Border Color:';
//                     const borderColorPicker = createColorPicker(colors, '#000000'); // Default border color is black
//                     borderColorPicker.style.marginBottom = '10px';
//                     borderColorPicker.addEventListener('change', function (event) {
//                         const color = event.target.value;
//                         handleBorderColorSelection(polygon, color);
//                     });
            
//                     // Create a button to delete the polygon
//                     const deleteBtn = document.createElement('button');
//                     deleteBtn.textContent = 'Delete Polygon';
//                     deleteBtn.style.marginTop = '10px';
//                     deleteBtn.addEventListener('click', function () {
//                         polygon.setMap(null);
//                         infoWindow.close();
//                     });
            
//                     // Create a paragraph to display coordinates
//                     const coordinatesParagraph = document.createElement('p');
//                     coordinatesParagraph.textContent = 'Coordinates:';
//                     contentContainer.appendChild(coordinatesParagraph);
//                     const path = polygon.getPath();
//                     const polygonCoordinates = path.getArray().map((latLng) => ({
//                         lat: latLng.lat(),
//                         lng: latLng.lng(),
//                     }));
//                     console.log('Polygon Coordinates:', polygonCoordinates);
//                     polygonCoordinates.forEach(coordinate => {
//                         const coordinateItem = document.createElement('p');
//                         coordinateItem.textContent = coordinate.lat + ', ' + coordinate.lng;
//                         contentContainer.appendChild(coordinateItem);
//                     });
            
//                     // Append elements to the content container
//                     contentContainer.appendChild(fillColorLabel);
//                     contentContainer.appendChild(fillColorPicker);
//                     contentContainer.appendChild(borderColorLabel);
//                     contentContainer.appendChild(borderColorPicker);
//                     contentContainer.appendChild(deleteBtn);
            
//                     // Create info window with the content container
//                     const infoWindow = new google.maps.InfoWindow({
//                         content: contentContainer,
//                     });
            
//                     // Open the info window when the polygon is clicked
//                     google.maps.event.addListener(polygon, 'click', function (event) {
//                         infoWindow.setPosition(event.latLng);
//                         infoWindow.open(map);
//                     });
//                 }
//             });
            
//             // Function to create a color picker with predefined color options
//             function createColorPicker(colors, defaultColor) {
//                 const colorPicker = document.createElement('input');
//                 colorPicker.type = 'color';
//                 colorPicker.value = defaultColor;
//                 colorPicker.style.marginBottom = '10px';
//                 colorPicker.setAttribute('list', 'colors');
                
//                 const datalist = document.createElement('datalist');
//                 datalist.id = 'colors';
//                 colors.forEach(color => {
//                     const option = document.createElement('option');
//                     option.value = color;
//                     datalist.appendChild(option);
//                 });
            
//                 colorPicker.appendChild(datalist);
                
//                 return colorPicker;
//             }
            
//             // Function to handle fill color selection
//             function handleFillColorSelection(polygon, color) {
//                 polygon.setOptions({
//                     fillColor: color,
//                     fillOpacity: 0.5, // Set fill opacity to 50%
//                 });
//                 console.log('Fill Color:', color);
//             }
            
//             // Function to handle border color selection
//             function handleBorderColorSelection(polygon, color) {
//                 polygon.setOptions({
//                     strokeColor: color,
//                     strokeOpacity: 1, // Set stroke opacity to 100%
//                     strokeWeight: 3, // Set stroke weight to 3 pixels
//                 });
//                 console.log('Border Color:', color);
//             }
            

//             google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
//             google.maps.event.addListener(map, 'click', clearSelection);
//             google.maps.event.addListener(drawingManager, 'click', function () {
//                 var polygonPaths = this.getPaths();
//                 console.log(polygonPaths);
//             });

//             if(accountType === "Admin" || accountType ==="Super-Admin" ){
//                 drawingManager.setMap(map);
//             }

//             drawingManager.setMap(map);
            
//             initAutocomplete();
//         },

//         (error) => {
//             console.error('Error getting user location:', error);
//             // Fallback to default location
//             const defaultLocation = { lat: 22.307159, lng: 73.181221 };
//             const map = new google.maps.Map(mapRef.current, {
//                 center: defaultLocation,
//                 zoom: 15,
//                 streetViewControl: false,
//             });
//             map.setOptions({ minZoom: 7, maxZoom: 20 });

//             const initAutocomplete = () => {
//                 const input = document.createElement('input');
//                 input.id = 'pac-input';
//                 input.type = 'text';
//                 input.placeholder = 'Search places...';
//                 input.style.width = '50%';
//                 input.style.padding = '10px';
//                 input.style.margin = '10px 0';
//                 input.style.borderRadius = '5px';
//                 input.style.border = '1px solid #ccc';
//                 map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);

//                 const searchBox = new google.maps.places.SearchBox(input);
//                 setSearchBox(searchBox);


//                 searchBox.addListener('places_changed', () => {
//                     const places = searchBox.getPlaces();

//                     if (places.length === 0) {
//                         return;
//                     }


//                     const bounds = new google.maps.LatLngBounds();
//                     places.forEach((place) => {
//                         if (place.geometry && place.geometry.viewport) {
//                             bounds.union(place.geometry.viewport);
//                         } else {
//                             bounds.extend(place.geometry.location);
//                         }

                        
//                     });

//                     map.fitBounds(bounds);

//                 });
//             };

//             drawingManager = new google.maps.drawing.DrawingManager({
//                 drawingMode: google.maps.drawing.OverlayType.POLYGON,
//                 drawingControl: true,
//                 drawingControlOptions: {
//                     position: google.maps.ControlPosition.BOTTOM_CENTER,
//                     drawingModes: [
//                         google.maps.drawing.OverlayType.POLYGON,
//                     ],
//                 },
//                 polygonOptions: {
//                     clickable: true,
//                     fillColor: selectedColor, // Set the initial color from the selectedColor state
//                     fillOpacity: 0.5,
//                     strokeWeight: 3,
//                     zIndex: 1,
//                     editable: false,
//                 },
//             });

//             google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
//                 if (event.type !== google.maps.drawing.OverlayType.MARKER) {
//                     drawingManager.setDrawingMode(null);
            
//                     const newShape = event.overlay;
//                     newShape.type = event.type;
//                     google.maps.event.addListener(newShape, 'click', function () {
//                         setSelection(newShape);
//                     });
//                     setSelection(newShape);
            
//                     const polygon = event.overlay;
//                     setSelectedShape(polygon); // Set the selected shape
            
//                     // Define color options for both border and interior
//                     const colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF'];
            
//                     // Create a container for the info window content
//                     const contentContainer = document.createElement('div');
//                     contentContainer.style.display = 'flex';
//                     contentContainer.style.flexDirection = 'column';
//                     contentContainer.style.padding = '10px';
            
//                     // Create a color picker for the polygon fill color
//                     const fillColorLabel = document.createElement('label');
//                     fillColorLabel.textContent = 'Fill Color:';
//                     const fillColorPicker = createColorPicker(colors, selectedColor);
//                     fillColorPicker.style.marginBottom = '10px';
//                     fillColorPicker.addEventListener('change', function (event) {
//                         const color = event.target.value;
//                         handleFillColorSelection(polygon, color);
//                     });
            
//                     // Create a color picker for the polygon border color
//                     const borderColorLabel = document.createElement('label');
//                     borderColorLabel.textContent = 'Border Color:';
//                     const borderColorPicker = createColorPicker(colors, '#000000'); // Default border color is black
//                     borderColorPicker.style.marginBottom = '10px';
//                     borderColorPicker.addEventListener('change', function (event) {
//                         const color = event.target.value;
//                         handleBorderColorSelection(polygon, color);
//                     });
            
//                     // Create a button to delete the polygon
//                     const deleteBtn = document.createElement('button');
//                     deleteBtn.textContent = 'Delete Polygon';
//                     deleteBtn.style.marginTop = '10px';
//                     deleteBtn.addEventListener('click', function () {
//                         polygon.setMap(null);
//                         infoWindow.close();
//                     });
            
//                     // Create a paragraph to display coordinates
//                     const coordinatesParagraph = document.createElement('p');
//                     coordinatesParagraph.textContent = 'Coordinates:';
//                     contentContainer.appendChild(coordinatesParagraph);
//                     const path = polygon.getPath();
//                     const polygonCoordinates = path.getArray().map((latLng) => ({
//                         lat: latLng.lat(),
//                         lng: latLng.lng(),
//                     }));
//                     console.log('Polygon Coordinates:', polygonCoordinates);
//                     polygonCoordinates.forEach(coordinate => {
//                         const coordinateItem = document.createElement('p');
//                         coordinateItem.textContent = coordinate.lat + ', ' + coordinate.lng;
//                         contentContainer.appendChild(coordinateItem);
//                     });
            
//                     // Append elements to the content container
//                     contentContainer.appendChild(fillColorLabel);
//                     contentContainer.appendChild(fillColorPicker);
//                     contentContainer.appendChild(borderColorLabel);
//                     contentContainer.appendChild(borderColorPicker);
//                     contentContainer.appendChild(deleteBtn);
            
//                     // Create info window with the content container
//                     const infoWindow = new google.maps.InfoWindow({
//                         content: contentContainer,
//                     });
            
//                     // Open the info window when the polygon is clicked
//                     google.maps.event.addListener(polygon, 'click', function (event) {
//                         infoWindow.setPosition(event.latLng);
//                         infoWindow.open(map);
//                     });
//                 }
//             });
            
//             // Function to create a color picker with predefined color options
//             function createColorPicker(colors, defaultColor) {
//                 const colorPicker = document.createElement('input');
//                 colorPicker.type = 'color';
//                 colorPicker.value = defaultColor;
//                 colorPicker.style.marginBottom = '10px';
//                 colorPicker.setAttribute('list', 'colors');
                
//                 const datalist = document.createElement('datalist');
//                 datalist.id = 'colors';
//                 colors.forEach(color => {
//                     const option = document.createElement('option');
//                     option.value = color;
//                     datalist.appendChild(option);
//                 });
            
//                 colorPicker.appendChild(datalist);
                
//                 return colorPicker;
//             }
            
//             // Function to handle fill color selection
//             function handleFillColorSelection(polygon, color) {
//                 polygon.setOptions({
//                     fillColor: color,
//                     fillOpacity: 0.5, // Set fill opacity to 50%
//                 });
//                 console.log('Fill Color:', color);
//             }
            
//             // Function to handle border color selection
//             function handleBorderColorSelection(polygon, color) {
//                 polygon.setOptions({
//                     strokeColor: color,
//                     strokeOpacity: 1, // Set stroke opacity to 100%
//                     strokeWeight: 3, // Set stroke weight to 3 pixels
//                 });
//                 console.log('Border Color:', color);
//             }
            

//             google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
//             google.maps.event.addListener(map, 'click', clearSelection);
//             google.maps.event.addListener(drawingManager, 'click', function () {
//                 var polygonPaths = this.getPaths();
//                 console.log(polygonPaths);
//             });

//             if(accountType === "Admin" || accountType ==="Super-Admin" ){
//                 drawingManager.setMap(map);
//             }

//             drawingManager.setMap(map);
            
//             initAutocomplete();

//         }
//                 );
//     } else {

//         console.error('Geolocation is not supported by this browser.');
//         // Fallback to default location
//         const defaultLocation = { lat: 22.307159, lng: 73.181221 };
//         const map = new google.maps.Map(mapRef.current, {
//             center: defaultLocation,
//             zoom: 15,
//             streetViewControl: false,
//         });
//         map.setOptions({ minZoom: 7, maxZoom: 20 }); 


//         const initAutocomplete = () => {
//             const input = document.createElement('input');
//             input.id = 'pac-input';
//             input.type = 'text';
//             input.placeholder = 'Search places...';
//             input.style.width = '50%';
//             input.style.padding = '10px';
//             input.style.margin = '10px 0';
//             input.style.borderRadius = '5px';
//             input.style.border = '1px solid #ccc';
//             map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);

//             const searchBox = new google.maps.places.SearchBox(input);
//             setSearchBox(searchBox);


//             searchBox.addListener('places_changed', () => {
//                 const places = searchBox.getPlaces();

//                 if (places.length === 0) {
//                     return;
//                 }


//                 const bounds = new google.maps.LatLngBounds();
//                 places.forEach((place) => {
//                     if (place.geometry && place.geometry.viewport) {
//                         bounds.union(place.geometry.viewport);
//                     } else {
//                         bounds.extend(place.geometry.location);
//                     }

                    
//                 });

//                 map.fitBounds(bounds);

//             });
//         };

//         drawingManager = new google.maps.drawing.DrawingManager({
//             drawingMode: google.maps.drawing.OverlayType.POLYGON,
//             drawingControl: true,
//             drawingControlOptions: {
//                 position: google.maps.ControlPosition.BOTTOM_CENTER,
//                 drawingModes: [
//                     google.maps.drawing.OverlayType.POLYGON,
//                 ],
//             },
//             polygonOptions: {
//                 clickable: true,
//                 fillColor: selectedColor, // Set the initial color from the selectedColor state
//                 fillOpacity: 0.5,
//                 strokeWeight: 3,
//                 zIndex: 1,
//                 editable: false,
//             },
//         });

//         google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
//             if (event.type !== google.maps.drawing.OverlayType.MARKER) {
//                 drawingManager.setDrawingMode(null);
        
//                 const newShape = event.overlay;
//                 newShape.type = event.type;
//                 google.maps.event.addListener(newShape, 'click', function () {
//                     setSelection(newShape);
//                 });
//                 setSelection(newShape);
        
//                 const polygon = event.overlay;
//                 setSelectedShape(polygon); // Set the selected shape
        
//                 // Define color options for both border and interior
//                 const colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF'];
        
//                 // Create a container for the info window content
//                 const contentContainer = document.createElement('div');
//                 contentContainer.style.display = 'flex';
//                 contentContainer.style.flexDirection = 'column';
//                 contentContainer.style.padding = '10px';
        
//                 // Create a color picker for the polygon fill color
//                 const fillColorLabel = document.createElement('label');
//                 fillColorLabel.textContent = 'Fill Color:';
//                 const fillColorPicker = createColorPicker(colors, selectedColor);
//                 fillColorPicker.style.marginBottom = '10px';
//                 fillColorPicker.addEventListener('change', function (event) {
//                     const color = event.target.value;
//                     handleFillColorSelection(polygon, color);
//                 });
        
//                 // Create a color picker for the polygon border color
//                 const borderColorLabel = document.createElement('label');
//                 borderColorLabel.textContent = 'Border Color:';
//                 const borderColorPicker = createColorPicker(colors, '#000000'); // Default border color is black
//                 borderColorPicker.style.marginBottom = '10px';
//                 borderColorPicker.addEventListener('change', function (event) {
//                     const color = event.target.value;
//                     handleBorderColorSelection(polygon, color);
//                 });
        
//                 // Create a button to delete the polygon
//                 const deleteBtn = document.createElement('button');
//                 deleteBtn.textContent = 'Delete Polygon';
//                 deleteBtn.style.marginTop = '10px';
//                 deleteBtn.addEventListener('click', function () {
//                     polygon.setMap(null);
//                     infoWindow.close();
//                 });
        
//                 // Create a paragraph to display coordinates
//                 const coordinatesParagraph = document.createElement('p');
//                 coordinatesParagraph.textContent = 'Coordinates:';
//                 contentContainer.appendChild(coordinatesParagraph);
//                 const path = polygon.getPath();
//                 const polygonCoordinates = path.getArray().map((latLng) => ({
//                     lat: latLng.lat(),
//                     lng: latLng.lng(),
//                 }));
//                 console.log('Polygon Coordinates:', polygonCoordinates);
//                 polygonCoordinates.forEach(coordinate => {
//                     const coordinateItem = document.createElement('p');
//                     coordinateItem.textContent = coordinate.lat + ', ' + coordinate.lng;
//                     contentContainer.appendChild(coordinateItem);
//                 });
        
//                 // Append elements to the content container
//                 contentContainer.appendChild(fillColorLabel);
//                 contentContainer.appendChild(fillColorPicker);
//                 contentContainer.appendChild(borderColorLabel);
//                 contentContainer.appendChild(borderColorPicker);
//                 contentContainer.appendChild(deleteBtn);
        
//                 // Create info window with the content container
//                 const infoWindow = new google.maps.InfoWindow({
//                     content: contentContainer,
//                 });
        
//                 // Open the info window when the polygon is clicked
//                 google.maps.event.addListener(polygon, 'click', function (event) {
//                     infoWindow.setPosition(event.latLng);
//                     infoWindow.open(map);
//                 });
//             }
//         });
        
//         // Function to create a color picker with predefined color options
//         function createColorPicker(colors, defaultColor) {
//             const colorPicker = document.createElement('input');
//             colorPicker.type = 'color';
//             colorPicker.value = defaultColor;
//             colorPicker.style.marginBottom = '10px';
//             colorPicker.setAttribute('list', 'colors');
            
//             const datalist = document.createElement('datalist');
//             datalist.id = 'colors';
//             colors.forEach(color => {
//                 const option = document.createElement('option');
//                 option.value = color;
//                 datalist.appendChild(option);
//             });
        
//             colorPicker.appendChild(datalist);
            
//             return colorPicker;
//         }
        
//         // Function to handle fill color selection
//         function handleFillColorSelection(polygon, color) {
//             polygon.setOptions({
//                 fillColor: color,
//                 fillOpacity: 0.5, // Set fill opacity to 50%
//             });
//             console.log('Fill Color:', color);
//         }
        
//         // Function to handle border color selection
//         function handleBorderColorSelection(polygon, color) {
//             polygon.setOptions({
//                 strokeColor: color,
//                 strokeOpacity: 1, // Set stroke opacity to 100%
//                 strokeWeight: 3, // Set stroke weight to 3 pixels
//             });
//             console.log('Border Color:', color);
//         }
        

//         google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
//         google.maps.event.addListener(map, 'click', clearSelection);
//         google.maps.event.addListener(drawingManager, 'click', function () {
//             var polygonPaths = this.getPaths();
//             console.log(polygonPaths);
//         });

//         if(accountType === "Admin" || accountType ==="Super-Admin" ){
//             drawingManager.setMap(map);
//         }

//         drawingManager.setMap(map);
        
//         initAutocomplete();
//     }
// };

//         document.head.appendChild(script);
//     }, []);

//     return (
        
//         // <div className={`bg-gray-50 ${isDarkMode ? 'dark:bg-gray-700' : 'bg-sky-200'} flex flex-col items-center justify-center mt-[60px] h-[790px]`}>

//             <div id="map" ref={mapRef} style={{ width: '100%', height: '92%', position: 'absolute', marginTop: '60px',overflow:'hidden' }}  />

//         // {/* </div> */}
//     );
// };

// export default Home;


/* global google */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mapSetter } from "../services/operations/polygonAPI";
import addCurrentLocation from 'google-maps-current-location';

const Home = () => {
    const isDarkMode = useSelector((state) => state.darkMode);
    const { token } = useSelector((state) => state.auth)

    const dispatch = useDispatch();
    const mapRef = useRef(null);
    // const { accountType } = useSelector((state) => {
    //     if (state.profile.user && state.profile.user.accountType) {
    //         return state.profile.user.accountType;
    //     } else {
    //         return { ...state.profile.user, accountType: null };
    //     }
    // });
    // const profile = useSelector((state) => state.profile);
    // console.log("Profile",profile.user.accountType)
    // console.log("Account Type", profile.user.accountType);
    const [selectedShape, setSelectedShape] = useState(null);
    const [searchBox, setSearchBox] = useState(null);
    const [selectedColor, setSelectedColor] = useState("#000000"); // Default color is black
    // let drawingManager; // Declare drawingManager variable outside initMap function
    let polygon;

    const clearSelection = () => {
        if (selectedShape) {
            setSelectedShape(null);
        }
    };

    const setSelection = (shape) => {
        setSelectedShape(shape);
        shape.setEditable(false);
    };
    console.log("This is rendering twice");
    useEffect(() => {
        const successCallback = () => {
            console.log("Successfully Granted Location Permission");
        }

        const errorCallback = () => {
            console.error("Location Permission Denied");
        }

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);


        const script = document.createElement("script");
        script.id = "google-maps-script";
        script.src =
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyC9-d4Gq0-oEsTLJ00BpwJ90PacwLbk3rg&callback=initMap&libraries=drawing,places";
        script.defer = true;

        window.initMap = function () {

            // Get current position
            const defaultLocation = { lat: 22.3072, lng: 73.1812 }; // Default location
            const darkMapStyles = [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#242f3e"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#746855"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#242f3e"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#d59563"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#d59563"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#263c3f"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#6b9a76"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#38414e"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#212a37"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9ca5b3"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#746855"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#1f2835"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#f3d19c"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#2f3948"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#d59563"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#17263c"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#515c6d"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#17263c"
                        }
                    ]
                }
            ]
            const mapStyles = isDarkMode ? darkMapStyles : null;

            // Create map centered on user's current location
            const map = new google.maps.Map(mapRef.current, {
                center: defaultLocation,
                zoom: 15,
                streetViewControl: false,
                styles: mapStyles

            });
            map.setOptions({ minZoom: 7, maxZoom: 20 });


            const initAutocomplete = () => {
                const input = document.createElement("input");
                input.id = "pac-input";
                input.type = "text";
                input.placeholder = "Search places...";
                input.style.width = "50%";
                input.style.padding = "10px";
                input.style.margin = "10px 0";
                input.style.borderRadius = "5px";
                input.style.border = "1px solid #ccc";
                map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);

                const searchBox = new google.maps.places.SearchBox(input);
                setSearchBox(searchBox);

                searchBox.addListener("places_changed", () => {
                    const places = searchBox.getPlaces();

                    if (places.length === 0) {
                        return;
                    }

                    const bounds = new google.maps.LatLngBounds();
                    places.forEach((place) => {
                        if (place.geometry && place.geometry.viewport) {
                            bounds.union(place.geometry.viewport);
                        } else {
                            bounds.extend(place.geometry.location);
                        }
                    });

                    map.fitBounds(bounds);
                });
            };

            // drawingManager = new google.maps.drawing.DrawingManager({
            //     drawingMode: google.maps.drawing.OverlayType.POLYGON,
            //     drawingControl: true,
            //     drawingControlOptions: {
            //         position: google.maps.ControlPosition.BOTTOM_CENTER,
            //         drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            //     },
            //     polygonOptions: {
            //         clickable: true,
            //         fillColor: selectedColor, // Set the initial color from the selectedColor state
            //         fillOpacity: 0.5,
            //         strokeWeight: 3,
            //         zIndex: 1,
            //         editable: false,
            //     },
            // });

            // Assuming you have already initialized your Google Map instance and stored it in a variable named 'map'
            const infowindow = new google.maps.InfoWindow();

            // Function to fetch map data and construct polygons
            async function fetchMapDataAndConstructPolygons(map) {
                try {
                    const response = await fetch('https://api.bhoopati.com/api/v1/map/retrieve');
                    const data = await response.json();

                    const polygons = []; // Array to store polygons

                    data.forEach(map => {
                        const polygonData = {
                            coords: map.polygons.map(polygon => ({ lat: polygon.lat, lng: polygon.lng })),
                            fillColor: map.fillColor
                        };

                        // Create and push polygons to the array
                        polygonData.coords.push(polygonData.coords[0]); // Close the polygon loop
                        const polygon = new google.maps.Polygon({
                            paths: polygonData.coords,
                            fillColor: polygonData.fillColor,
                            fillOpacity: 0.5,
                            strokeColor: "#000000",
                            strokeWeight: 2
                        });

                        // Add click event listener to each polygon
                        google.maps.event.addListener(polygon, 'click', function (event) {
                            infowindow.setContent('<div>' +
                                '<strong>Polygon Information</strong><br>' +
                                'Fill Color: ' + polygonData.fillColor + '<br>' +
                                // Add more information as needed
                                '</div>');
                            infowindow.setPosition(event.latLng);
                            infowindow.open(map);
                        });

                        polygons.push(polygon);
                    });

                    // Set all polygons on the map
                    polygons.forEach(polygon => {
                        polygon.setMap(map);
                    });
                } catch (error) {
                    console.error('Error fetching map data:', error);
                }
            }

            // Call the function passing the 'map' object
            fetchMapDataAndConstructPolygons(map);

            // Function to handle fill color selection
            function handleFillColorSelection(polygon, color) {
                polygon.setOptions({
                    fillColor: color,
                    fillOpacity: 0.5, // Set fill opacity to 50%
                });
                console.log("Fill Color:", color);
                return color;
            }

            // google.maps.event.addListener(
            //     drawingManager,
            //     "overlaycomplete",
            //     function (event) {
            //         polygon = event.overlay;
            //         setSelectedShape(polygon);
            //         if (event.type !== google.maps.drawing.OverlayType.MARKER) {
            //             drawingManager.setDrawingMode(null);

            //             const newShape = event.overlay;
            //             newShape.type = event.type;
            //             google.maps.event.addListener(newShape, "click", function () {
            //                 setSelection(newShape);
            //             });
            //             setSelection(newShape);

            //             const polygon = event.overlay;
            //             setSelectedShape(polygon); // Set the selected shape

            //             // Define color options for both border and interior
            //             const colors = [
            //                 "#FF0000",
            //                 "#00FF00",
            //                 "#0000FF",
            //                 "#FFFF00",
            //                 "#FF00FF",
            //                 "#00FFFF",
            //             ];

            //             // Create a container for the info window content
            //             const contentContainer = document.createElement("div");
            //             contentContainer.style.display = "flex";
            //             contentContainer.style.flexDirection = "column";
            //             contentContainer.style.padding = "10px";

            //             // Create a color picker for the polygon fill color
            //             const fillColorLabel = document.createElement("label");
            //             fillColorLabel.textContent = "Fill Color:";
            //             const fillColorPicker = createColorPicker(
            //                 colors,
            //                 selectedColor
            //             );
            //             fillColorPicker.style.marginBottom = "10px";
            //             fillColorPicker.addEventListener("change", function (event) {
            //                 const color = event.target.value;
            //                 handleFillColorSelection(polygon, color);
            //             });

            //             // Create a color picker for the polygon border color
            //             const borderColorLabel = document.createElement("label");
            //             borderColorLabel.textContent = "Border Color:";
            //             const borderColorPicker = createColorPicker(
            //                 colors,
            //                 "#000000"
            //             ); // Default border color is black
            //             borderColorPicker.style.marginBottom = "10px";
            //             borderColorPicker.addEventListener(
            //                 "change",
            //                 function (event) {
            //                     const color = event.target.value;
            //                     handleBorderColorSelection(polygon, color);
            //                 }
            //             );

            //             // Create a button to delete the polygon
            //             const deleteBtn = document.createElement("button");
            //             deleteBtn.textContent = "Delete Polygon";
            //             deleteBtn.style.marginTop = "10px";
            //             deleteBtn.addEventListener("click", function () {
            //                 polygon.setMap(null);
            //                 infoWindow.close();
            //             });

            //             const color = 11;
            //             // const polygonCooordinates = [
            //             //     { lat: 25.774, lng: -80.19 },
            //             //     { lat: 18.466, lng: -66.118 },
            //             //     { lat: 32.321, lng: -64.757 },
            //             //     { lat: 25.774, lng: -80.19 },
            //             // ];

            //             // Create a paragraph to display coordinates
            //             const coordinatesParagraph = document.createElement("p");
            //             coordinatesParagraph.textContent = "Coordinates:";
            //             contentContainer.appendChild(coordinatesParagraph);
            //             const path = polygon.getPath();
            //             const polygonCoordinates = path.getArray().map((latLng) => ({
            //                 lat: latLng.lat(),
            //                 lng: latLng.lng(),
            //             }));
            //             console.log("Polygon :", polygon);
            //             console.log("Polygon Coordinates:", polygonCoordinates);
            //             //   console.log("Polygon Coordinates:", color);
            //             polygonCoordinates.forEach((coordinate) => {
            //                 const coordinateItem = document.createElement("p");
            //                 coordinateItem.textContent =
            //                     coordinate.lat + ", " + coordinate.lng;
            //                 contentContainer.appendChild(coordinateItem);
            //             });
            //             // Create a button to delete the polygon
            //             const saveBtn = document.createElement("button");
            //             saveBtn.textContent = "SAVE";
            //             saveBtn.style.marginTop = "10px";
            //             saveBtn.addEventListener("click", function () {
            //                 console.log("BHAI SHURU HUA: ");
            //                 dispatch(mapSetter(polygonCoordinates, color, token));
            //                 console.log(polygonCoordinates, color);
            //                 console.log("DONE");
            //             });


            //             // Append elements to the content container
            //             contentContainer.appendChild(fillColorLabel);
            //             contentContainer.appendChild(fillColorPicker);
            //             contentContainer.appendChild(borderColorLabel);
            //             contentContainer.appendChild(borderColorPicker);
            //             contentContainer.appendChild(saveBtn);

            //             contentContainer.appendChild(deleteBtn);

            //             // Create info window with the content container
            //             const infoWindow = new google.maps.InfoWindow({
            //                 content: contentContainer,
            //             });


            //             // Open the info window when the polygon is clicked
            //             google.maps.event.addListener(
            //                 polygon,
            //                 "click",
            //                 function (event) {
            //                     infoWindow.setPosition(event.latLng);
            //                     infoWindow.open(map);
            //                 }
            //             );
            //             google.maps.event.addListener(map, "click", function (event) {
            //                 // Check if the click event occurred inside the polygon
            //                 if (
            //                     !google.maps.geometry.poly.containsLocation(
            //                         event.latLng,
            //                         polygon
            //                     )
            //                 ) {
            //                     infoWindow.close(); // Close the info window
            //                 }
            //             });
            //         }
            //     }
            // );

            // // Function to create a color picker with predefined color options
            // function createColorPicker(colors, defaultColor) {
            //     const colorPicker = document.createElement("input");
            //     colorPicker.type = "color";
            //     colorPicker.value = defaultColor;
            //     colorPicker.style.marginBottom = "10px";
            //     colorPicker.setAttribute("list", "colors");

            //     const datalist = document.createElement("datalist");
            //     datalist.id = "colors";
            //     colors.forEach((color) => {
            //         const option = document.createElement("option");
            //         option.value = color;
            //         datalist.appendChild(option);
            //     });

            //     colorPicker.appendChild(datalist);

            //     return colorPicker;
            // }


            // // Function to handle border color selection
            // function handleBorderColorSelection(polygon, color) {
            //     polygon.setOptions({
            //         strokeColor: color,
            //         strokeOpacity: 1, // Set stroke opacity to 100%
            //         strokeWeight: 3, // Set stroke weight to 3 pixels
            //     });
            //     console.log("Border Color:", color);
            // }

            // google.maps.event.addListener(
            //     drawingManager,
            //     "drawingmode_changed",
            //     clearSelection
            // );
            // google.maps.event.addListener(map, "click", clearSelection);
            // google.maps.event.addListener(drawingManager, "click", function () {
            //     var polygonPaths = this.getPaths();
            //     console.log(polygonPaths);
            // });

            // Function to handle key press event
            // function handleKeyPress(event) {
            //     // Check if the pressed key is ESC (key code 27)
            //     if (event.keyCode === 27) {
            //         drawingManager.setDrawingMode(null); // Set drawing mode to null
            //         if (polygon) {
            //             polygon.setMap(null); // Set the polygon to null
            //             polygon = null; // Reset the polygon variable
            //         }
            //     }
            // }

            // Add event listener for key press
            // document.addEventListener("keydown", handleKeyPress);
            addCurrentLocation(map)
            // if (profile.user.accountType === "Admin" || profile.user.accountType === "Super-Admin") {
            //     drawingManager.setMap(map);
            // }

            initAutocomplete();

        };



        document.head.appendChild(script);
    }, [isDarkMode]);

    return (
        // <div className={`bg-gray-50 ${isDarkMode ? 'dark:bg-gray-700' : 'bg-sky-200'} flex flex-col items-center justify-center mt-[60px] h-[790px]`}>

        <div
            id="map"
            ref={mapRef}
            style={{
                width: "100%",
                height: "calc(100% - 60px)", /* Adjusted height with 60px removed for navigation bar */
                position: "absolute",
                marginTop: "60px", /* Keep 60px margin for navigation bar */
                overflow: "hidden",
            }}
            tabIndex="0" // Make the map focusable
        />

        // {/* </div> */}
    );
};

export default Home;
