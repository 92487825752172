import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineDelete, AiOutlineClose } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import BounceLoader from 'react-spinners';
import BounceLoader from "react-spinners/BounceLoader";

const generateChatId = () => {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};

const GeneralChatUI = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state.darkMode);
  const [models, setModels] = useState([]); // State to hold models fetched from API
  const [selectedModel, setSelectedModel] = useState(""); // State to hold the selected model
  // Function to fetch models from the API
  useEffect(() => {
    fetch(process.env.REACT_APP_OLLAMA_API+'/api/tags')
      .then(response => response.json())
      .then(data => {
        setModels(data.models.map(model => ({
          name: model.name,
          value: model.model
        })));
      })
      .catch(error => {
        console.error('Error fetching models:', error);
      });
  }, []);

  // Handler for model selection change
  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  // JSX for the model selector dropdown
  const modelSelector = (
    <select value={selectedModel} onChange={handleModelChange}>
      <option value="">Select a Model</option>
      {models.map(model => (
        <option key={model.value} value={model.value}>{model.name}</option>
      ))}
    </select>
  );

  const [chatHistories, setChatHistories] = useState(() => {
    const storedChatHistories = localStorage.getItem('chatHistories');
    return storedChatHistories ? JSON.parse(storedChatHistories) : {};
  });
  const [currentChatId, setCurrentChatId] = useState(() => {
    const storedCurrentChatId = localStorage.getItem('currentChatId');
    return storedCurrentChatId || generateChatId();
  });
  const [message, setMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [inputFocus, setInputFocus] = useState(true);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const inputRef = useRef(null);
  const chatContainerRef = useRef(null);

  const SidebarComponent = ({ currentChatId, setCurrentChatId, chatHistories, deleteChat, isDarkMode }) => {
    const startNewChat = () => {
      const newChatId = generateChatId();
      setCurrentChatId(newChatId);
      inputRef.current.focus();
    };

    return (
      <div className={`w-1/6 h-full border-r border-gray-300 select-none ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`}>
        <div className="p-4 justify-center">
          <button
            className={`py-2 px-4 bg-blue-500 text-white font-semibold rounded-md select-none ${isDarkMode ? 'hover:bg-blue-600 focus:ring focus:ring-blue-800' : 'hover:bg-blue-600 focus:ring focus:ring-blue-300'}`}
            onClick={startNewChat}
          >
            New Chat
          </button>
          <h2 className={`text-lg font-semibold mt-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Chat History</h2>
          <div className="mt-4">
            {Object.entries(chatHistories)
              .reverse() // Reverse the order of the entries
              .map(([chatId, history]) => (
                <div className="relative">
                  <div
                    key={chatId}
                    className={`mb-2 rounded-lg shadow-md p-4 flex justify-between items-center ${isDarkMode ? 'bg-gray-500' : 'bg-sky-200'
                      } cursor-pointer`}
                    onClick={() => setSelectedChatId(chatId)}
                  >
                    <div className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                      {`${history[0]?.user.split(' ').slice(0, 5).join(' ')}...`}
                    </div>
                  </div>
                  <div
                    className={`absolute right-5 top-1/2 transform -translate-y-1/2 ml-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} hover:text-red-500 cursor-pointer`}
                    onClick={() => deleteChat(chatId)}
                  >
                    <AiOutlineDelete />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const ChatHistoryModal = ({ chatHistories, selectedChatId, closeModal }) => {
    const isDarkMode = useSelector((state) => state.darkMode);
    const selectedChatHistory = chatHistories[selectedChatId] || [];

    return (
      <div
        className={`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 ${selectedChatId ? '' : 'hidden'}`}
        onClick={closeModal}
      >
        <div
          className={`bg-white rounded-lg shadow-lg p-6 max-h-full overflow-y-auto ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Chat History</h2>
            <button
              className={`text-gray-500 hover:text-gray-700 ${isDarkMode ? 'text-gray-400 hover:text-gray-200' : ''}`}
              onClick={closeModal}
            >
              <AiOutlineClose size={20} />
            </button>
          </div>
          {selectedChatHistory.map((chat, index) => (
            <React.Fragment key={index}>
              <div className={`flex mb-4 ${chat.user ? 'justify-end' : 'justify-start'}`}>
                <div className={`rounded py-2 px-4 max-w-md ${chat.user ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}>
                  {chat.user ? chat.user : chat.bot}
                </div>
              </div>
              <div className={`flex mb-4 ${chat.user ? 'justify-start' : 'justify-end'}`}>
                <div className={`rounded py-2 px-4 max-w-md ${chat.user ? 'bg-gray-300 text-gray-800' : 'bg-blue-500 text-white'}`}>
                  {chat.bot ? chat.bot : chat.user}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const sendMessage = async () => {
    setSendingMessage(true);
    const chatHistory = chatHistories[currentChatId] || [];
    const newChatHistory = [...chatHistory, { user: message, bot: '' }];
    setChatHistories({
      ...chatHistories,
      [currentChatId]: newChatHistory,
    });
    localStorage.setItem('chatHistories', JSON.stringify(chatHistories));
    setMessage('');
    try {
      const response = await fetch(process.env.REACT_APP_OLLAMA_API+'/api/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: selectedModel,
          prompt: message,
          stream: true,
        }),
      });

      const reader = response.body.getReader();
      let decoder = new TextDecoder();
      let partialMessage = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });

        // Split incoming chunks into messages
        const lines = (partialMessage + chunk).split('\n');
        partialMessage = lines.pop(); // Keep the incomplete message for the next iteration

        lines.forEach(line => {
          const data = JSON.parse(line);
          const updatedChatHistory = [...newChatHistory];
          updatedChatHistory[newChatHistory.length - 1].bot += data.response; // Append response to the last message
          setChatHistories({
            ...chatHistories,
            [currentChatId]: updatedChatHistory,
          });
          localStorage.setItem('chatHistories', JSON.stringify(chatHistories)); // Update chat history in localStorage
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSendingMessage(false);
    }
  };

  const deleteChat = (chatId) => {
    const updatedChatHistories = { ...chatHistories };
    delete updatedChatHistories[chatId];
    setChatHistories(updatedChatHistories);
    localStorage.setItem('chatHistories', JSON.stringify(updatedChatHistories));
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (message.trim() !== '') {
      await sendMessage();
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [chatHistories]);

  useEffect(() => {
    if (!sendingMessage && inputFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [sendingMessage, inputFocus]);

  useEffect(() => {
    navigate(`/dashboard/generalChatUI#${currentChatId}`, { replace: true });
    localStorage.setItem('currentChatId', currentChatId);
  }, [currentChatId, navigate]);

  return (
    <div className={`flex ${isDarkMode ? 'bg-gray-800' : 'bg-sky-200'} overflow-hidden pt-14 h-screen`}>
      <SidebarComponent
        currentChatId={currentChatId}
        setCurrentChatId={setCurrentChatId}
        chatHistories={chatHistories}
        deleteChat={deleteChat}
        isDarkMode={isDarkMode}
      />
      <div className="w-5/6 h-full flex flex-col">
      {modelSelector}

        {/* <CREATE A MODEL SELECTOR> */}
        <div className="flex-1 p-4 overflow-y-auto" ref={chatContainerRef}>
          {(chatHistories[currentChatId] || []).map((chat, index) => (
            <React.Fragment key={index}>
              <div className={`flex mb-4 ${chat.user ? 'justify-end' : 'justify-start'}`}>
                <div className={`rounded py-2 px-4 max-w-md ${chat.user ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}>
                  {chat.user ? chat.user : chat.bot}
                </div>
              </div>
              <div className={`flex mb-4 ${chat.user ? 'justify-start' : 'justify-end'}`}>
                        <div className={`rounded py-2 px-4 max-w-md ${chat.user ? 'bg-gray-300 text-gray-800' : 'bg-blue-500 text-white'}`}>
                          {chat.bot ? chat.bot : <BounceLoader color='#3b82f6' />}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>

        <form onSubmit={handleSendMessage} className="flex items-center p-4 border-t border-gray-300">
          <input
            ref={inputRef}
            type="text"
            name="message"
            id="message"
            className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 ${isDarkMode ? 'text-white bg-gray-800' : 'text-gray-900 bg-gray-100'}`}
            placeholder="Type your message here"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            disabled={sendingMessage}
          />
          <button
            type="submit"
            className={`ml-2 py-2 px-4 bg-blue-500 text-white font-semibold rounded-md ${isDarkMode ? 'hover:bg-blue-600 focus:ring focus:ring-blue-800' : 'hover:bg-blue-600 focus:ring focus:ring-blue-300'}`}
            disabled={sendingMessage}
          >
            {sendingMessage ? 'Answering...' : 'Send'}
          </button>
        </form>
      </div>
      <ChatHistoryModal
        chatHistories={chatHistories}
        selectedChatId={selectedChatId}
        closeModal={() => setSelectedChatId(null)}
      />
    </div>
  );
};

export default GeneralChatUI;