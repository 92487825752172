import React, { useState, useEffect, useRef } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../services/operations/authAPI";
import { toggleDarkMode as toggleDarkModeAction } from "../slices/darkModeSlice";

const Navbar = () => {
  const { user } = useSelector((state) => state.profile)
  const isDarkMode = useSelector((state) => state.darkMode);
  const [activeItem, setActiveItem] = useState("home");
  const [showDropdown, setShowDropdown] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    // Add event listener to close dropdown on document click
    document.addEventListener("click", handleDocumentClick);

    return () => {
      // Cleanup event listener on component unmount
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    setShowDropdown(false);
  };

  const toggleDarkMode = () => {
    dispatch(toggleDarkModeAction());
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDocumentClick = (e) => {
    // Close dropdown if the click is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(e.target))  {
      setShowDropdown(false);
    }
  };

  // Log the value of isDarkMode to console whenever it changes
  useEffect(() => {
    console.log("Dark mode:", isDarkMode);
  }, [isDarkMode]);

  return (
    <nav
      className={`bg-gray-200 ${
        isDarkMode ? "dark:bg-gray-800 " : "bg-gray-100 "
      } p-2 h-[60px] fixed w-full top-0 z-10 shadow-md `}
    >
      <div className="container mx-auto flex space-x-4 justify-between items-center relative">
        <Link
          to="/dashboard"
          className={`text-${
            isDarkMode ? "white " : "black "
          } text-xl font-bold`}
        >
          Logo
        </Link>
        <div className="flex space-x-6">
          <DarkModeSwitch
            className="rounded-[100px] mr-3 no-transition"
            checked={isDarkMode}
            onChange={toggleDarkMode}
            sunColor="black"
            moonColor="white"
            size={35}
          />
          {token ? (
            <div className="relative inline-block" ref={dropdownRef}>
              <button
                className={`rounded-full select-none ${
                  isDarkMode
                    ? "bg-slate-700 text-white hover:bg-gray-600 "
                    : "bg-white text-black hover:bg-gray-100 "
                } h-[8vw] w-[8vw] md:h-[40px] md:w-[40px] focus:outline-none focus:border-none`}
                onClick={toggleDropdown}
              >
                <img
                  src={user?.image}
                  alt="user-avatar"
                  className="w-full h-full object-cover rounded-full"
                />
              </button>
              {showDropdown && (
                <div
                  className={`absolute right-0 mt-2 ${
                    isDarkMode
                      ? "bg-slate-700 text-white"
                      : "bg-white text-black"
                  } rounded-md shadow-md z-20`}
                >
                  <Link
                    to="/profile"
                    onClick={() => handleItemClick("profile")}
                  >
                    <button
                      className={`w-full py-2 px-4 text-left hover:${
                        isDarkMode
                          ? "bg-slate-800 hover:bg-gray-600"
                          : "bg-gray-100 hover:bg-gray-100"
                      } rounded-md focus:outline-none`}
                    >
                      Profile
                    </button>
                  </Link>
                  <Link
                    to="/dashboard"
                    onClick={() => handleItemClick("profile")}
                  >
                    <button
                      className={`w-full py-2 px-4 text-left hover:${
                        isDarkMode
                          ? "bg-slate-800 hover:bg-gray-600"
                          : "bg-gray-100 hover:bg-gray-100"
                      } rounded-md focus:outline-none`}
                    >
                      Dashboard
                    </button>
                  </Link>

                  <Link
                    to="/settings"
                    onClick={() => handleItemClick("settings")}
                  >
                    <button
                      className={`w-full py-2 px-4 text-left hover:${
                        isDarkMode
                          ? "bg-slate-800 hover:bg-gray-600"
                          : "bg-gray-100 hover:bg-gray-100"
                      } rounded-md focus:outline-none`}
                    >
                      Settings
                    </button>
                  </Link>
                  <div
                    onClick={() => {
                      dispatch(logout(navigate));
                      setShowDropdown(false);
                    }}
                  >
                    <button
                      className={`w-full py-2 px-4 text-left hover:${
                        isDarkMode
                          ? "bg-slate-800 hover:bg-gray-600"
                          : "bg-gray-100 hover:bg-gray-100"
                      } rounded-md focus:outline-none`}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <Link to="/login" onClick={() => handleItemClick("login")}>
                <button
                  className={`rounded-[8px] select-none ${
                    isDarkMode
                      ? "bg-slate-700 text-white hover:bg-gray-600 "
                      : "bg-white text-black hover:bg-gray-100 "
                  } px-[12px] py-[8px] focus:outline-none focus:border-none`}
                >
                  Login
                </button>
              </Link>
              <Link to="/signup" onClick={() => handleItemClick("signup")}>
                <button
                  className={`rounded-[8px] select-none ${
                    isDarkMode
                      ? "bg-slate-700 text-white hover:bg-gray-600 "
                      : "bg-white text-black hover:bg-gray-100 "
                  } px-[12px] py-[8px] focus:outline-none focus:border-none`}
                >
                  Sign Up
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
