import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "./ReactToastify.css";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import PasswordStrengthBar from "react-password-strength-bar";
import { useSelector,useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setSignupData } from "../slices/authSlice";
import { sendOtp } from "../services/operations/authAPI";

const PasswordInput = ({
  label,
  value,
  onChange,
  showPassword,
  togglePasswordVisibility,
  isDarkMode,
}) => {
  return (
    <div className="relative">
      <label
        htmlFor={label}
        className={`${isDarkMode ? "text-white" : "text-gray-900"
          } block mb-2 text-sm font-medium select-none`}
      >
        {label}
      </label>
      <div>
        <input
          type={showPassword ? "text" : "password"}
          name={label}
          id={label}
          className={`bg-gray-50 border ${isDarkMode
              ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
              : "border-gray-300 text-gray-900"
            } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
          placeholder={label}
          value={value}
          onChange={onChange}
          required
        />
        <span
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-[38px] z-[10] cursor-pointer"
        >
          {showPassword ? (
            <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
          ) : (
            <AiOutlineEye fontSize={24} fill="#AFB2BF" />
          )}
        </span>
      </div>
    </div>
  );
};

const SignupForm = () => {
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [username, setUsername] = useState("");
  const isDarkMode = useSelector((state) => state.darkMode);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  })
  
  const handleOnChange =(e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }
  const { firstName, lastName, email } = formData
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignup = (e) => {
    e.preventDefault();
    // Check if passwords match and meet criteria
    if (password !== confirmPassword) {
      toast.error("Passwords do not match", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (password.length < 8) {
      toast.error("Password must be at least 8 characters long", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (password.search(/[a-z]/i) < 0) {
      toast.error("Password must contain at least one letter", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (password.search(/[0-9]/) < 0) {
      toast.error("Password must contain at least one digit", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (password.search(/[!@#$%^&*]/) < 0) {
      toast.error("Password must contain at least one special character", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.success("Success", { position: toast.POSITION.BOTTOM_RIGHT });

      const signupData = {
        ...formData, password,confirmPassword
      }

      
      dispatch(setSignupData(signupData))
      // console.log(signupData)
      console.log(formData.email)
      dispatch(sendOtp(formData.email, navigate));
    }
  };

  const togglePasswordVisibility = (e) => {
    e.persist(); // Add this line
    setShowPassword(!showPassword);
  };
  return (
    <section
      className={`bg-gray-50 ${isDarkMode ? "dark:bg-gray-700" : "bg-sky-200"
        } min-h-screen flex items-center justify-center`}
    >
      <div
        className={`w-full items-center justify-center max-w-md p-6 bg-gray-100 rounded-lg shadow-lg ${isDarkMode ? "dark:border dark:bg-gray-800 dark:border-gray-700" : ""
          }`}
        style={{ marginTop: "25px" }}
      >
        <h1
          className={`text-xl font-bold text-center select-none ${isDarkMode ? "text-white" : "text-gray-900"
            } mb-10`}
        >
          Create an Account
        </h1>
        <form className="space-y-4" onSubmit={handleSignup}>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="firstName"
                className={`${isDarkMode ? "text-white" : "text-gray-900"
                  } block mb-2 text-sm font-medium select-none`}
              >
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className={`bg-gray-50 border ${isDarkMode
                    ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                    : "border-gray-300 text-gray-900"
                  } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="First Name"
                value={firstName}
                // onChange={(e) => setFirstName(e.target.value)}
                onChange={handleOnChange}
                required
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className={`${isDarkMode ? "text-white" : "text-gray-900"
                  } block mb-2 text-sm font-medium select-none`}
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className={`bg-gray-50 border ${isDarkMode
                    ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                    : "border-gray-300 text-gray-900"
                  } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Last Name"
                value={lastName}
                // onChange={(e) => setLastName(e.target.value)}
                onChange={handleOnChange}
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className={`${isDarkMode ? "text-white" : "text-gray-900"
                } block mb-2 text-sm font-medium select-none`}
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className={`bg-gray-50 border ${isDarkMode
                  ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                  : "border-gray-300 text-gray-900"
                } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              placeholder="Email"
              value={email}
              // onChange={(e) => setEmail(e.target.value)}
              onChange={handleOnChange}
              required
            />
          </div>

          <PasswordInput
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            // onChange={handleOnChange}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
            isDarkMode={isDarkMode}
          />

          <PasswordInput
            label="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            // onChange={handleOnChange}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
            isDarkMode={isDarkMode}
          />
          <PasswordStrengthBar password={password} className="select-none" />
          <button
            type="submit"
            className={`w-full text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center select-none ${isDarkMode
                ? "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 select-none"
                : ""
              }`}
          >
            Sign up
          </button>
          <p
            className={`text-sm font-light ${isDarkMode ? "dark:text-gray-400" : "text-gray-500"
              } select-none`}
          >
            Already have an account? {" "}
            <Link
              to="/login"
              className={`font-medium ${isDarkMode
                  ? "dark:text-blue-500 hover:underline"
                  : "text-blue-600 hover:underline"
                } select-none`}
            >
              Log In
            </Link>
          </p>
          <ToastContainer limit={2} />
        </form>
      </div>
    </section>
  );
};

export default SignupForm;
