import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./ReactToastify.css";
import { useSelector,useDispatch } from "react-redux";
import { login } from "../services/operations/authAPI"

const PasswordInput = ({
  label,
  value,
  onChange,
  showPassword,
  togglePasswordVisibility,
  isDarkMode,
}) => {
  return (
    <div className="relative">
      <label
        htmlFor={label}
        className={`${isDarkMode ? "text-white" : "text-gray-900"
          } block mb-2 text-sm font-medium select-none`}
      >
        {label}
      </label>
      <div>
        <input
          type={showPassword ? "text" : "password"}
          name={label}
          id={label}
          className={`bg-gray-50 border ${isDarkMode
              ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
              : "border-gray-300 text-gray-900"
            } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
          placeholder={label}
          value={value}
          onChange={onChange}
          required
        />
        <span
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-[38px] z-[10] cursor-pointer"
        >
          {showPassword ? (
            <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
          ) : (
            <AiOutlineEye fontSize={24} fill="#AFB2BF" />
          )}
        </span>
      </div>
    </div>
  );
};
const LoginForm = () => {
  const isDarkMode = useSelector((state) => state.darkMode);

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const handleRememberMe = () => {
    setIsChecked(!isChecked);
    console.log(!isChecked);
  };


  const handleSignIn = (e) => {
    e.preventDefault()
    dispatch(login(email, password, navigate))
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Add your login logic here
    console.log("Logging in with:", { email, password });
  };

  const togglePasswordVisibility = (e) => {
    e.persist(); // Add this line
    setShowPassword(!showPassword);
  };

  return (
    <section
      className={`bg-gray-50 ${isDarkMode ? "dark:bg-gray-700" : "bg-sky-200"
        } min-h-screen flex items-center justify-center`}
    >
      <div
        className={`w-full items-center justify-center max-w-md p-6 bg-gray-100 rounded-lg shadow-lg ${isDarkMode ? "dark:border dark:bg-gray-800 dark:border-gray-700" : ""
          }`}
      >
        <h1
          className={`text-xl font-bold text-center select-none ${isDarkMode ? "text-white" : "text-gray-900"
            } mb-10`}
        >
          Log In to Your Account
        </h1>
        <form className="space-y-4" onSubmit={handleSignIn}>
          <div>
            <label
              htmlFor="email"
              className={`${isDarkMode ? "text-white" : "text-gray-900"
                } block mb-2 text-sm font-medium select-none`}
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className={`bg-gray-50 border ${isDarkMode
                  ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                  : "border-gray-300 text-gray-900"
                } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <PasswordInput
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
            isDarkMode={isDarkMode}
          />

          <div className="flex items-center justify-between">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="remember"
                  type="checkbox"
                  className={`w-4 h-4 border ${isDarkMode
                      ? "dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800"
                      : "border-gray-300 bg-gray-50 focus:ring-3 focus:ring-primary-300"
                    }`}
                  checked={isChecked}
                  onChange={handleRememberMe}
                />
              </div>
              <div className="ml-3 text-sm select-none">
                <label
                  htmlFor="remember"
                  className={`${isDarkMode ? "dark:text-gray-300" : "text-gray-500"
                    }`}
                >
                  Remember me
                </label>
              </div>
            </div>
            <a className="text-sm font-medium text-blue-600 hover:underline select-none">
              <Link
                to="/forgetpassword"
                className={`font-medium ${isDarkMode
                    ? "dark:text-blue-500 hover:underline"
                    : "text-blue-600 hover:underline"
                  } select-none`}
              >
                Forgot Password
              </Link>
            </a>
          </div>
          <button
            type="submit"
            onClick={handleSignIn}
            className={`w-full text-white ${isDarkMode
                ? "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                : "bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
              } font-medium rounded-full text-sm mt-5 px-5 py-2.5 text-center select-none`}
          >
            Sign in
          </button>
          <ToastContainer limit={2} />
          <p
            className={`text-sm font-light ${isDarkMode ? "dark:text-gray-400" : "text-gray-500"
              } select-none`}
          >
            Don't have an account yet? {" "}
            <Link
              to="/signup"
              className={`font-medium ${isDarkMode
                  ? "dark:text-blue-500 hover:underline"
                  : "text-blue-600 hover:underline"
                } select-none`}
            >
              Sign up
            </Link>
          </p>

          <ToastContainer limit={2} />
        </form>
      </div>
    </section>
  );
};

export default LoginForm;
