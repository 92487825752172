import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import image1 from '../assets/general-chat.png';
import image2 from '../assets/embeddings-img.png';

const Dashboard = () => {
    const isDarkMode = useSelector((state) => state.darkMode);
    const navigate = useNavigate(); // Initialize useNavigate

    const handleImageClick = (imageIndex) => {
        if (imageIndex === 1) {
            // Implement the logic for the corresponding image click
            navigate('/dashboard/generalChatUI'); // Navigate to /dashboard/general-chat
        }
        if (imageIndex === 2) {
            // Implement the logic for the corresponding image click
            // navigate('/dashboard/generalChatUI'); // Navigate to /dashboard/general-chat
        }
        if (imageIndex === 3) {
            // Implement the logic for the corresponding image click
            // navigate('/dashboard/generalChatUI'); // Navigate to /dashboard/general-chat
        }
        if (imageIndex === 4) {
            // Implement the logic for the corresponding image click
            // navigate('/dashboard/generalChatUI'); // Navigate to /dashboard/general-chat
        }
        if (imageIndex === 5) {
            // Implement the logic for the corresponding image click
            // navigate('/dashboard/generalChatUI'); // Navigate to /dashboard/general-chat
        }
    };

    return (
        <section
            className={`bg-gray-50 ${isDarkMode ? 'dark:bg-gray-700' : 'bg-sky-200'
                } min-h-screen pt-16 flex flex-wrap justify-center `}
        >
          <div className={`grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3  rounded-lg p-3 `}>

                <ImageButton
                    imageIndex={1}
                    image={image1}
                    alt="Image 1"
                    onClick={handleImageClick}
                />
                <ImageButton
                    imageIndex={2}
                    image={image2}
                    alt="Image 2"
                    onClick={handleImageClick}
                />
                <ImageButton
                    imageIndex={3}
                    image={image2}
                    alt="Image 3"
                    onClick={handleImageClick}
                />
                <ImageButton
                    imageIndex={4}
                    image={image2}
                    alt="Image 4"
                    onClick={handleImageClick}
                />
                <ImageButton
                    imageIndex={5}
                    image={image2}
                    alt="Image 5"
                    onClick={handleImageClick}
                />
            </div>

        </section>
    );
};

const ImageButton = ({ imageIndex, image, alt, onClick }) => {
    return (
        <div className="m-4">
            <img
                src={image}
                alt={alt}
                className="flex justify-center items-center w-screen md:h-60 lg:h-80 rounded-lg cursor-pointer transition duration-300 transform hover:scale-110 shadow-md bg-white dark:bg-gray-800"
                onClick={() => onClick(imageIndex)}
            />
        </div>
    );
};

export default Dashboard;