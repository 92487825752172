import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Home from './pages/Home';
import ForgetPassword from './pages/ForgetPassword';
import Otp from './pages/Otp';
import OtpAcc from './pages/OtpAcc';
import OtpDel from './pages/OtpDel';
import OtpChangeEmail from './pages/Otp_EmailChange';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import Navbar from './components/Navbar';
// import Footer from './components/Footer';
import GeneralChatUI from './pages/GeneralChatUI';
import React, { useState } from 'react';
import OpenRoute from './components/Auth/OpenRoute';
import PrivateRoute from './components/Auth/PrivateRoute';
// import Home from "./pages/Home/Home.js";
import Dashboard from './pages/Dashboard';

function App() {
  const [isDarkMode, setDarkMode] = useState(false);

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
  };

  return (
    <Router>
      <div className = {`overflow-hidden`} >
        <Navbar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        <Routes>
          <Route path="/" element={<OpenRoute><Home isDarkMode={isDarkMode} /></OpenRoute>}/>
          <Route path="/login" element={<OpenRoute><Login isDarkMode={isDarkMode} /></OpenRoute>} />
          <Route path="/signup" element={<OpenRoute><Signup isDarkMode={isDarkMode} /></OpenRoute>} />
          <Route path="/otp" element={<OpenRoute><Otp isDarkMode={isDarkMode} /></OpenRoute>} />
          <Route
            path="/forgetpassword"
            element={<OpenRoute><ForgetPassword isDarkMode={isDarkMode} /></OpenRoute>}
            />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard isDarkMode={isDarkMode} /></PrivateRoute>} />

          <Route path="/dashboard/generalChatUI" element={<PrivateRoute><GeneralChatUI isDarkMode={isDarkMode} /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile isDarkMode={isDarkMode} /></PrivateRoute>} />
          <Route path="/profile/account-details" element={<PrivateRoute><EditProfile isDarkMode={isDarkMode} /></PrivateRoute>} />
          <Route path="/otp-account-details" element={<PrivateRoute><OtpAcc isDarkMode={isDarkMode} /></PrivateRoute>} />
          <Route path="/otpdel" element={<PrivateRoute><OtpDel isDarkMode={isDarkMode} /></PrivateRoute>} />
          <Route path="/otp-email-change" element={<PrivateRoute><OtpChangeEmail isDarkMode={isDarkMode} /></PrivateRoute>} />
        </Routes>
        {/* <Footer isDarkMode={isDarkMode} /> */}
      </div>
    </Router>
  );
}

export default App;
