import React, { useState } from "react";
import { toast } from "react-hot-toast";
import "./ReactToastify.css";
import { TiEdit } from "react-icons/ti";
import { useSelector, useDispatch } from "react-redux";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { EditProfile } from "../services/operations/authAPI";
import { sendOtpAcc } from "../services/operations/authAPI";
import { deleteAccOtp } from "../services/operations/authAPI";
import { IoCaretBackOutline } from "react-icons/io5";

const EditProfileForm = () => {
  const isDarkMode = useSelector((state) => state.darkMode);
  const { user } = useSelector((state) => state.profile);
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    username: user.username,
    contactNumber: user.contactNumber,
  });
  const [editMode, setEditMode] = useState({
    firstName: false,
    lastName: false,
    username: false,
    contactNumber: false,
    image: false,
  });

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleBack = () => {
    navigate('/dashboard'); // Navigate to /profile when back button is clicked
  };

  const { firstName, lastName, username, contactNumber } = formData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [showPopup, setShowPopup] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isInputValid, setInputValid] = useState(false);

  const handleEditProfile = (e) => {
    e.preventDefault();
    dispatch(EditProfile(formData, token));

    toast.success("Changes Saved Successfully", {
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setInputValid(value === "DELETE");
  };


  const handleEditAccount = (e) => {
    e.preventDefault();
    dispatch(sendOtpAcc(token, navigate));
    
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setShowPopup(false);
  };

  const handleDeleteAccount = (e) => {
    e.preventDefault() && e.preventDefault();
    dispatch(deleteAccOtp(token, navigate));
    
  };

  const toggleEditMode = (field) => {
    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFormData((prevData) => ({
        ...prevData,
        image: reader.result, // Set the image data to the formData
      }));
    };

    if (file) {
      reader.readAsDataURL(file); // Convert image to base64 string
    }
  };

  return (
    <section
      className={`bg-gray-50 ${isDarkMode ? "dark:bg-gray-700" : "bg-sky-200"
        } min-h-screen flex items-center justify-center`}
    >
      <div
        className={`w-full items-center justify-center max-w-xl p-6 bg-gray-100 rounded-lg shadow-lg ${isDarkMode ? "dark:border dark:bg-gray-800 dark:border-gray-700" : ""
          }`}
      >
                <IoCaretBackOutline
          onClick={handleBack}
          size="25px"
          className={`bg-gray-50 items-center justify-center rounded-lg shadow-lg ${isDarkMode ? "dark:bg-gray-700" : "bg-sky-200"
            } flex cursor-pointer`}
        />

        <div className="flex justify-center relative">
          {editMode.image ? (
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e)}
            />
          ) : (
            <a
              href="img"
              onClick={(e) => {
                e.preventDefault();
                toggleEditMode("image");
              }}
              className="relative group"
            >
              <img
                src={formData.image || user?.image}
                alt="user-avatar"
                className={`object-cover w-[150px] rounded-full transition duration-300 hover:bg-black ${editMode.image ? "filter brightness-70" : ""
                  } hover:brightness-70 filter blur-0 group-hover:blur-[2px]`}
              />
              {editMode.image && (
                <div className="absolute inset-0 bg-black opacity-50 rounded-full"></div>
              )}
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <TiEdit />
              </span>
            </a>
          )}
        </div>

        <div className="flex justify-center">
          <h1
            className={`text-xl font-bold text-center select-none ${isDarkMode ? "text-white" : "text-gray-900"
              } mb-5 mt-5`}
          >
            Profile Details
          </h1>
        </div>
        <form className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="firstName"
                className={`${isDarkMode ? "text-white" : "text-gray-900"
                  } block mb-2 text-sm font-medium select-none flex justify-between`}
              >
                First Name
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className={`bg-gray-50 border focus:outline-none ${isDarkMode
                      ? !editMode.firstName
                        ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:bg-gray-700"
                        : "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                      : !editMode.firstName
                        ? "border-gray-300 text-gray-500"
                        : "border-gray-300 text-gray-900"
                    } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="First Name"
                  value={firstName}
                  onChange={handleOnChange}
                  required
                  readOnly={!editMode.firstName}
                />
                <label
                  htmlFor="firstName"
                  className={`${isDarkMode ? "text-white" : "text-gray-900"
                    } block mb-2 text-sm font-medium select-none flex justify-between absolute top-3 right-3 cursor-pointer`}
                >
                  <span onClick={() => toggleEditMode("firstName")}>
                    <TiEdit />
                  </span>
                </label>
              </div>
            </div>
            <div>
              <label
                htmlFor="lastName"
                className={`${isDarkMode ? "text-white" : "text-gray-900"
                  } block mb-2 text-sm font-medium select-none flex justify-between`}
              >
                Last Name
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className={`bg-gray-50 border focus:outline-none ${isDarkMode
                      ? !editMode.lastName
                        ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:bg-gray-700"
                        : "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                      : !editMode.lastName
                        ? "border-gray-300 text-gray-500"
                        : "border-gray-300 text-gray-900"
                    } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Last Name"
                  value={lastName}
                  onChange={handleOnChange}
                  required
                  readOnly={!editMode.lastName}
                />
                <label
                  htmlFor="lastName"
                  className={`${isDarkMode ? "text-white" : "text-gray-900"
                    } block mb-2 text-sm font-medium select-none flex justify-between absolute top-3 right-3 cursor-pointer`}
                >
                  <span onClick={() => toggleEditMode("lastName")}>
                    <TiEdit />
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="contactNumber"
                className={`${isDarkMode ? "text-white" : "text-gray-900"
                  } block mb-2 text-sm font-medium select-none flex justify-between`}
              >
                Contact Number
              </label>
              <div className="relative">
                <input
                  type="tel"
                  name="contactNumber"
                  id="contactNumber"
                  className={`bg-gray-50 border focus:outline-none ${isDarkMode
                      ? !editMode.contactNumber
                        ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:bg-gray-700"
                        : "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                      : !editMode.contactNumber
                        ? "border-gray-300 text-gray-500"
                        : "border-gray-300 text-gray-900"
                    } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Contact Number"
                  pattern="[0-9]{10}"
                  value={contactNumber}
                  onChange={handleOnChange}
                  required
                  readOnly={!editMode.contactNumber}
                />
                <label
                  htmlFor="contactNumber"
                  className={`${isDarkMode ? "text-white" : "text-gray-900"
                    } block mb-2 text-sm font-medium select-none flex justify-between absolute top-3 right-3 cursor-pointer`}
                >
                  <span onClick={() => toggleEditMode("contactNumber")}>
                    <TiEdit />
                  </span>
                </label>
              </div>
            </div>
            <div>
              <label
                htmlFor="username"
                className={`${isDarkMode ? "text-white" : "text-gray-900"
                  } block mb-2 text-sm font-medium select-none flex justify-between`}
              >
                Username
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="username"
                  id="username"
                  className={`bg-gray-50 border focus:outline-none ${isDarkMode
                      ? !editMode.username
                        ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:bg-gray-700"
                        : "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                      : !editMode.username
                        ? "border-gray-300 text-gray-500"
                        : "border-gray-300 text-gray-900"
                    } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Username"
                  value={username}
                  onChange={handleOnChange}
                  required
                  readOnly={!editMode.username}
                />
                <label
                  htmlFor="username"
                  className={`${isDarkMode ? "text-white" : "text-gray-900"
                    } block mb-2 text-sm font-medium select-none flex justify-between absolute top-3 right-3 cursor-pointer`}
                >
                  <span onClick={() => toggleEditMode("username")}>
                    <TiEdit />
                  </span>
                </label>
              </div>
            </div>
          </div>

          <button
            type="submit"
            onClick={handleEditProfile}
            className={`text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center select-none flex items-center justify-center mx-auto ${isDarkMode
                ? "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 select-none focus:outline-none"
                : ""
              }`}
            style={{
              width: "460px",
              ...(window.innerWidth <= 768 && { maxWidth: "100%" }),
            }}
          >
            Save Changes
          </button>

          <div className="flex">
            <button
              type="submit"
              onClick={handleEditAccount}
              className={`flex text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center select-none items-center justify-center mx-auto ${isDarkMode
                  ? "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 select-none focus:outline-none"
                  : ""
                }`}
              style={{ width: "200px" }}
            >
              Account Details
            </button>
            <button
              type="submit"
              onClick={handleDeleteClick}
              className={`flex text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center select-none items-center justify-center mx-auto ${isDarkMode
                  ? "dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 select-none focus:outline-none"
                  : ""
                }`}
              style={{ width: "200px" }}
            >
              Delete Account
            </button>

            {showPopup && (
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm z-50 flex items-center justify-center">
                <div
                  className={`w-full items-center justify-center max-w-xl p-6 bg-gray-100 rounded-lg shadow-lg ${isDarkMode ? "dark:border dark:bg-gray-800 dark:border-gray-700" : ""
                    }`}
                >
                  <p className={`text-lg text-center select-none ${isDarkMode ? "text-white" : "text-gray-900"
                    } mb-5 mt-5`}>
                    Are you sure you want to delete your account? This action is
                    irreversible.
                  </p>
                  <p className={`text-md  text-center select-none ${isDarkMode ? "text-white" : "text-gray-900"
                    } mb-5 mt-5`}>
                    To delete your account, type "DELETE"
                  </p>
                  <input
                    type="text"
                    className={`bg-gray-50 border focus:outline-none ${isDarkMode
                        ? !editMode.username
                          ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:bg-gray-700"
                          : "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                        : !editMode.username
                          ? "border-gray-300 text-gray-500"
                          : "border-gray-300 text-gray-900"
                      } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Type DELETE to confirm"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <div className="mt-4"></div>
                  <div className="flex">
                    <button
                      type="submit"
                      onClick={handleCancel}
                      className={`flex text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center select-none items-center justify-center mx-auto ${isDarkMode
                          ? "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 select-none focus:outline-none"
                          : ""
                        }`}
                      style={{ width: "200px" }}
                    >
                      Cancel Delete
                    </button>
                    <button
                      type="submit"
                      onClick={handleDeleteAccount}
                      className={`flex text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center select-none items-center justify-center mx-auto ${isDarkMode
                          ? "dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 select-none focus:outline-none"
                          : ""
                        } ${isInputValid ? "" : " cursor-not-allowed"}`}
                      style={{ width: "200px" }}
                      disabled={!isInputValid}
                    >
                      Confirm Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditProfileForm;
