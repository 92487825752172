import { toast } from "react-hot-toast";

import { setLoading, setToken } from "../../slices/authSlice";
import { setUser } from "../../slices/profileSlice";
import { apiConnector } from "../apiconnector";
import { polygonEndpoints } from "../apis";
import { useNavigate } from "react-router-dom";

const { SAVE_POLYGON_COORDINATES, LOAD_POLYGON_COORDINATES } = polygonEndpoints;

export function mapSetter(coordinates, fillColor,description,token) {
  return async (dispatch) => {

    const toastId = toast.loading("Wait while your coordinates are being saved.");

    dispatch(setLoading(true));
    try {
      console.log("trying to connect to api")
      const response = await apiConnector("POST", SAVE_POLYGON_COORDINATES, { coordinates, fillColor,description },{
              Authorization: `Bearer ${token}`,
      });
      
      toast.success("Coordinates saved successfully");
      // const shouldReload = window.confirm("Reload page to see changes?");
      // if (shouldReload) {
      //     // Reload the page if the user confirms
      //     window.location.reload();
      // } else {
      //     // Handle the case when the user cancels the action
      //     console.log("User canceled the reload action.");
      // }


    } catch (error) {
      console.log("API KI ERROR HAI", error);
      toast.error("Error polygon not saved");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}
