import React, { useEffect,useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { changeEmail } from "../services/operations/authAPI";
import { useDispatch, useSelector } from "react-redux";



function Otp() {

  const [Otp, setOtp] = useState("");
  const { changedEmail } = useSelector((state) => state.auth);
  const isDarkMode = useSelector((state) => state.darkMode);
  const token = useSelector((state) => state.auth.token);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!changedEmail) {
      navigate("/profile/account-details");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      changeEmail(
          changedEmail,
          Otp,
          token,
        navigate
      )
    );
  };

return (
    <section
      className={`bg-gray-50  ${
        isDarkMode ? "dark:bg-gray-700 " : "bg-sky-200 "
      } min-h-screen flex items-center justify-center`}
    >
      <div
        className={` w-full items-center justify-center max-w-md p-6 bg-gray-100 rounded-lg shadow-lg ${
          isDarkMode ? "dark:border dark:bg-gray-800 dark:border-gray-500 " : "dark:border bg-gray-100 border-slate-600 "
        }`}
      >
        <h1
          className={`text-xl font-bold text-center select-none  ${
            isDarkMode ? "text-white " : "text-gray-900 "
          } mb-10`}
        >
          Enter the OTP sent to your email
        </h1>
        <form onSubmit={handleSubmit}>
          <OtpInput
            value={Otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => (
              <input
                {...props}
                placeholder="-"
                style={{}}
                className={"w-[48px] lg:w-[60px] bg-lime-200 border-0 rounded-[0.5rem] text-richblack-200 aspect-square text-center focus:border-0 focus:outline-2 focus:outline-yellow-200"}
              />
            )}
            containerStyle={{
              justifyContent: "space-between",
              gap: "0 6px",
            }}
          />
          <br />
          <button
            type="submit"
            className={`w-full text-white  ${
              isDarkMode
                ? "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 "
                : "bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 "
            } font-medium rounded-full text-sm px-5 py-2.5 text-center select-none`}
          >
            Verify Email
          </button>
        </form>
      </div>
    </section>
  );
}

export default Otp;