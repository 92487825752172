import { toast } from "react-hot-toast";
import { setLoading, setToken } from "../../slices/authSlice";
import { setUser } from "../../slices/profileSlice";
import { apiConnector } from "../apiconnector";
import { endpoints } from "../apis";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"
import { useState } from "react";

const {
  SENDOTP_API,
  SENDOTPACC_API,
  SIGNUP_API,
  EDITPROFILE_API,
  VERIFYUSERFIRST_API,
  LOGIN_API,
  DELETEACC_API,
  CHANGE_PASSWORD_API,
  ACCOUNT_TYPE_API,
  CHANGE_EMAIL_API
} = endpoints;

export function sendOtp(email, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");

    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SENDOTP_API, { email });
      console.log("SENDOTP API RESPONSE............", response);

      console.log(response.data.success);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("OTP Sent Successfully");
      navigate("/otp");
    } catch (error) {
      console.log("SENDOTP API ERROR............", error);
      toast.error(error);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function sendOtpChangeEmail(email, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");

    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SENDOTP_API, { email });
      console.log("SENDOTP API RESPONSE............", response);

      console.log(response.data.success);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("OTP Sent Successfully");
      navigate("/otp-email-change");
    } catch (error) {
      console.log("SENDOTP API ERROR............", error);
      toast.error(error);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function sendOtpAcc(token, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");

    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SENDOTPACC_API,{},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      console.log("SENDOTPACC API RESPONSE............", response);

      console.log(response.data.success);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("OTP Sent Successfully");
      navigate("/otp-account-details");
    } catch (error) {
      console.log("SENDOTPACC API ERROR............", error);
      toast.error(error);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function deleteAccOtp(token, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");

    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SENDOTPACC_API,{},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      console.log("SENDOTPACC API RESPONSE............", response);

      console.log(response.data.success);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("OTP Sent Successfully");
      navigate("/otpdel");
    } catch (error) {
      console.log("SENDOTPACC API ERROR............", error);
      toast.error(error);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function changeEmail(
  email,
  Otp,
  token,
  navigate
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    // const navigate = useNavigate();
    try {
      const response = await apiConnector("POST", CHANGE_EMAIL_API, {
        email,
        Otp
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      console.log("CHANGE EMAIL API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      toast.success("Email Changed Successfully. Please Login Again");
      dispatch(setToken(null));
      dispatch(setUser(null));
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      // toast.success("Logged Out");
      navigate("/login");
    } catch (error) {
      console.log("CHANGE EMAIL API ERROR............", error);
      toast.error(error.response.data.message);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}
export function signUp(
  firstName, //
  lastName, //
  email, //
  password, //
  confirmPassword, //
  Otp,
  navigate
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    // const navigate = useNavigate();
    try {
      console.log(
        firstName, //
        lastName, //
        email, //
        password, //
        confirmPassword, //
        Otp
      );
      const response = await apiConnector("POST", SIGNUP_API, {
        firstName, //
        lastName, //
        email, //
        password, //
        confirmPassword, //
        Otp, //
      });

      console.log("SIGNUP API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      toast.success("Signup Successful");
      navigate("/login");
    } catch (error) {
      console.log("SIGNUP API ERROR............", error);
      toast.error(error.response.data.message);
      // navigate("/signup")
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function delteAccount(
  Otp,
  token,
  navigate
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    // const navigate = useNavigate();
    try {
      const response = await apiConnector("POST", DELETEACC_API, {
        Otp},
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        });

      console.log("DELETEACC API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      toast.success("Account Deleted Successfully");
      dispatch(setToken(null));
      dispatch(setUser(null));
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      toast.success("Logged Out");
      navigate("/");
    } catch (error) {
      console.log("DELETEACC API ERROR............", error);
      toast.error(error.response.data.message);
      // navigate("/signup")
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}
export function verifyUserFirst(
  Otp,
  token,
  navigate
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    // const navigate = useNavigate();
    try {
      const response = await apiConnector("POST", VERIFYUSERFIRST_API, {
        Otp},
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        });

      console.log("VERIFYUSERFIRST API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      toast.success("Verification Successful");
      navigate("/profile/account-details");
    } catch (error) {
      console.log("VERIFYUSERFIRST API ERROR............", error);
      toast.error(error.response.data.message);
      // navigate("/signup")
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function EditProfile(
  formData,token //
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));

    try {
 
      const response = await apiConnector(
        "POST",
        EDITPROFILE_API,
        formData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log("EDITPROFILE API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      toast.success("EditProfile Successful");
      dispatch(setUser({...response.data.user}));
      localStorage.setItem("user", JSON.stringify(response.data.user));
    } catch (error) {
      console.log("EDITPROFILE API ERROR............", error);
      toast.error(error.response.data.message);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function login(email, password, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", LOGIN_API, {
        email,
        password,
      });

      console.log("LOGIN API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("Login Successful");
      dispatch(setToken(response.data.token));
      const userImage = response.data?.user?.image
        ? response.data.user.image
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.firstName} ${response.data.user.lastName}`;
      dispatch(setUser({ ...response.data.user, image: userImage }));

      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("user", JSON.stringify(response.data.user));
      navigate("/dashboard");
    } catch (error) {
      console.log("LOGIN API ERROR............", error);
      toast.error(error.response.data.message);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    toast.success("Logged Out");
    navigate("/");
  };
}

export function resetPassword(oldPassword, newPassword, confirmPassword, token,navigate) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await apiConnector("POST", CHANGE_PASSWORD_API, {
        oldPassword,
        newPassword,
        confirmPassword,
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      console.log("Change Password RESPONSE ... ", response);

      if (!response.data.success) {
        // toast.error(response.data.message);
        // console.log("Change Password RESPONSE ... ", response);
        throw new Error(response.data.message);
      }

      toast.success("Password Changed Successfully. Please Login Again.");
      dispatch(setToken(null));
      dispatch(setUser(null));
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      // toast.success("Logged Out");
      navigate("/login");

    } catch (error) {
      console.log("Change Password ERROR ... ", error);
      toast.error(error.response.data.message);
    }
    dispatch(setLoading(false));
  };
}
