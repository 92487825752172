import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const isDarkMode = useSelector((state) => state.darkMode);


  const handleForgetPassword = (e) => {
    e.preventDefault();
    // Add your login logic here
    console.log("Logging in with:", { email });
  };

  const responseGoogle = (response) => {
    // Handle the Google login response here
    console.log("Google Login Response:", response);
  };

  const responseApple = (response) => {
    // Handle the Apple login response here
    console.log("Apple Login Response:", response);
  };

  return (
    <section
      className={`bg-gray-50  ${
        isDarkMode ? "dark:bg-gray-700 " : "bg-sky-200 "
      } min-h-screen flex items-center justify-center`}
    >
      <div
        className={` w-full items-center justify-center max-w-md p-6 bg-gray-100 rounded-lg shadow-lg ${
          isDarkMode ? "dark:border dark:bg-gray-800 dark:border-gray-500 " : "dark:border bg-gray-100 border-slate-600 "
        }`}
      >
        <h1
          className={`text-xl font-bold text-center select-none  ${
            isDarkMode ? "text-white " : "text-gray-900 "
          } mb-10`}
        >
          Forgot Password 
        </h1>
        <form className="space-y-4" onSubmit={handleForgetPassword}>
          <div className="gap-4">
            <div>
              <label
                htmlFor="email"
                className={` ${
                  isDarkMode ? "text-white " : "text-gray-900 "
                } block mb-2 text-sm font-medium select-none`}
              >
                Registered Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className={`bg-gray-50 border  ${
                  isDarkMode
                    ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700 "
                    : "border-gray-300 text-gray-900 "
                } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className={`w-full text-white  ${
              isDarkMode
                ? "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 "
                : "bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 "
            } font-medium rounded-full text-sm px-5 py-2.5 text-center select-none`}
          >
            Send Recovery Mail
          </button>
          <div className="flex items-center justify-between">
                <div className="flex items-end">
                </div>
                <a className="text-sm font-medium text-blue-600 hover:underline select-none">
                <Link to="/login" className={`font-medium  ${isDarkMode ? 'dark:text-blue-500 hover:underline ' : 'text-blue-600 hover:underline '} select-none`}>
                  Back to Login
                </Link>
                </a>
              </div>
          <p
            className={`text-sm font-light  ${
              isDarkMode ? "dark:text-gray-400 " : "text-gray-500 "
            } select-none`}
          >
            Don't have an account yet? {" "}
            <Link
              to="/signup"
              className={`font-medium  ${
                isDarkMode
                  ? "dark:text-blue-500 hover:underline "
                  : "text-blue-600 hover:underline "
              } select-none`}
            >
              Sign up
            </Link>
          </p>
        </form>
      </div>
    </section>
  );
};

export default ForgotPasswordForm;
