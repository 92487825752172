import React, { useState } from "react";
import { toast } from "react-hot-toast";
import "./ReactToastify.css";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import PasswordStrengthBar from "react-password-strength-bar";
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setEmail } from "../slices/authSlice";
import { sendOtpChangeEmail, resetPassword } from "../services/operations/authAPI";
import { TiEdit } from "react-icons/ti";
import { IoCaretBackOutline } from "react-icons/io5";

const PasswordInput = ({
  label,
  value,
  onChange,
  showPassword,
  togglePasswordVisibility,
  isDarkMode,
}) => {
  return (
    <div className="relative">
      <label
        htmlFor={label}
        className={`${isDarkMode ? "text-white" : "text-gray-900"
          } block mb-2 text-sm font-medium select-none`}
      >
        {label}
      </label>
      <div>
        <input
          type={showPassword ? "text" : "password"}
          name={label}
          id={label}
          className={`bg-gray-50 border ${isDarkMode
            ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
            : "border-gray-300 text-gray-900"
            } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
          placeholder={label}
          value={value}
          onChange={onChange}
          required
        />
        <span
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-[38px] z-[10] cursor-pointer"
        >
          {showPassword ? (
            <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
          ) : (
            <AiOutlineEye fontSize={24} fill="#AFB2BF" />
          )}
        </span>
      </div>
    </div>
  );
};

const EditProfileForm = () => {
  const isDarkMode = useSelector((state) => state.darkMode);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useSelector((state) => state.profile);
  const [formData, setFormData] = useState({
    email: user.email
  });
  const [editMode, setEditMode] = useState({
    email: false
  });
  const profile = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }
  const { email } = formData
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate('/profile'); // Navigate to /profile when back button is clicked
  };


  const handleChangePass = (e) => {
    e.preventDefault();
    // Check if passwords match and meet criteria
    if (password !== confirmPassword) {
      toast.error("Passwords do not match", {
      });
    } else if (password.length < 8) {
      toast.error("Password must be at least 8 characters long", {
      });
    } else if (password.search(/[a-z]/i) < 0) {
      toast.error("Password must contain at least one letter", {
      });
    } else if (password.search(/[0-9]/) < 0) {
      toast.error("Password must contain at least one digit", {
      });
    } else if (password.search(/[!@#$%^&*]/) < 0) {
      toast.error("Password must contain at least one special character", {
      });
    } else {

      dispatch(resetPassword(oldPassword, password, confirmPassword, token));
    }
  };



  const handleEmailChange = (e) => {
    e.preventDefault();

    dispatch(setEmail(formData.email))
    dispatch(sendOtpChangeEmail(formData.email, navigate));
  };

  const toggleEditMode = (field) => {
    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const togglePasswordVisibility = (e) => {
    e.persist(); // Add this line
    setShowPassword(!showPassword);
  };

  return (
    <section
      className={`bg-gray-50 ${isDarkMode ? "dark:bg-gray-700" : "bg-sky-200"
        } min-h-screen flex items-center justify-center`}
    >

      <div
        className={`w-full items-center justify-center max-w-xl p-6 bg-gray-100 rounded-lg shadow-lg ${isDarkMode ? "dark:border dark:bg-gray-800 dark:border-gray-700" : ""
          }`}
      >
        <IoCaretBackOutline
          onClick={handleBack}
          size="25px"
          className={`bg-gray-50 items-center justify-center rounded-lg shadow-lg ${isDarkMode ? "dark:bg-gray-700" : "bg-sky-200"
            } flex cursor-pointer`}
        />

        <div className="flex justify-center">
          <img
            src={user?.image}
            alt="user-avatar"
            className="object-cover w-[100px] rounded-full"
          />
        </div>
        <div className="flex justify-center">
          <h1
            className={`text-xl font-bold text-center select-none ${isDarkMode ? "text-white" : "text-gray-900"
              } mb-10`}
          >
            Account Details
          </h1>
        </div>
        <form className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className={`${isDarkMode ? "text-white" : "text-gray-900"
                } flex mb-2 text-sm font-medium select-none  justify-between`}
            >
              Email
            </label>
            <div className="relative">
              <input
                type="text"
                name="email"
                id="email"
                className={`bg-gray-50 border focus:outline-none ${isDarkMode
                  ? !editMode.email ? "dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:bg-gray-700" : "dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700"
                  : !editMode.email ? "border-gray-300 text-gray-500" : "border-gray-300 text-gray-900"
                  } sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Email"
                value={email}
                onChange={handleOnChange}
                required
                readOnly={!editMode.email}
              />

              {(profile.user.accountType === "Customer") &&
                (
                  <label
                    htmlFor="email"
                    className={`${isDarkMode ? "text-white" : "text-gray-900"
                      } flex mb-2 text-sm font-medium select-none  justify-between absolute top-3 right-3 cursor-pointer`}
                  >
                    <span onClick={() => toggleEditMode("email")}>
                      <TiEdit />
                    </span>
                  </label>
                )}
            </div>
          </div>


          <PasswordInput
            label="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
            isDarkMode={isDarkMode}
          />

          <PasswordInput
            label="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
            isDarkMode={isDarkMode}
          />

          <PasswordInput
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
            isDarkMode={isDarkMode}
          />

          <PasswordStrengthBar password={password} className="select-none" />

          <div className="flex">
            <button
              type='submit'
              onClick={handleEmailChange}
              className={`flex text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center select-none items-center justify-center mx-auto ${isDarkMode
                ? "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 select-none focus:outline-none"
                : ""
                }`}
              style={{ width: "200px" }}
            >
              Change Email
            </button>
            <button
              type='submit'
              onClick={handleChangePass}
              className={`flex text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center select-none items-center justify-center mx-auto ${isDarkMode
                ? "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 select-none focus:outline-none"
                : ""
                }`}
              style={{ width: "200px" }}
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default EditProfileForm;

