import { createSlice } from "@reduxjs/toolkit";

// Function to get initial state from localStorage or default to false (light mode)
const getInitialDarkModeState = () => {
  const storedDarkMode = localStorage.getItem("darkMode");
  console.log("Stored dark mode:", storedDarkMode);
  try {
    if (storedDarkMode !== null) {
      return JSON.parse(storedDarkMode);
    }
  } catch (error) {
    console.error("ERROR PARSING JSON:", error);
  }
  return false;
};

const initialState = getInitialDarkModeState(); // Get initial state from localStorage or default to light mode

const darkModeSlice = createSlice({
  name: "darkMode",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      // Toggle dark mode state
      const newState = !state;
      // Save new state to localStorage
      localStorage.setItem("darkMode", JSON.stringify(newState));
      return newState;
    },
  },
});

export const { toggleDarkMode } = darkModeSlice.actions;
export default darkModeSlice.reducer;
