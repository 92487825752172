const BASE_URL = "https://api-se.manansantoki.xyz/api/v1";

// AUTH ENDPOINTS
export const endpoints = {
  SENDOTP_API: BASE_URL + "/auth/sendotp",
  SIGNUP_API: BASE_URL + "/auth/signup",
  EDITPROFILE_API: BASE_URL + "/auth/updateProfile",
  SENDOTPACC_API: BASE_URL + "/auth/generateOTP",
  VERIFYUSERFIRST_API: BASE_URL + "/auth/verify",
  LOGIN_API: BASE_URL + "/auth/login",
  DELETEACC_API: BASE_URL + "/auth/deleteAcc",
  CHANGE_PASSWORD_API: BASE_URL + "/auth/changePassword",
  ACCOUNT_TYPE_API: BASE_URL + "/auth/accountType",
  CHANGE_EMAIL_API: BASE_URL + "/auth/changeEmail",
};

//polygon APIs

export const polygonEndpoints = {
  SAVE_POLYGON_COORDINATES: BASE_URL + "/map/save",
  LOAD_POLYGON_COORDINATES: BASE_URL + "/map/retrieve",
  //CHANGE_PASSWORD_API: BASE_URL + "/auth/changepassword",
  //DELETE_PROFILE_API: BASE_URL + "/profile/deleteProfile",
};
