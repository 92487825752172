import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import profileReducer from "../slices/profileSlice";
import darkModeReducer from "../slices/darkModeSlice"; // Import the dark mode reducer

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  darkMode: darkModeReducer, // Add the dark mode reducer here
});

export default rootReducer;
